import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { IHSRCone } from '../../../common/model/graphql-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './hsr-light-cone-card.scss';
import { Accordion } from 'react-bootstrap';

interface IProps {
  cone: IHSRCone;
  number?: number;
  superImp?: string;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const HSRLightConeProfile: React.FC<IProps> = ({
  cone,
  number,
  superImp
}) => {
  const image = cone.smallImage
    ? getImage(cone.smallImage.localFile.childImageSharp)
    : null;
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          {number && <span className="cone-priority">{number}</span>}
          <div className={`hsr-set-image rarity-${cone.rarity}`}>
            {image && (
              <GatsbyImage
                className="disable-transition"
                image={image}
                alt={`${cone.name}`}
              />
            )}
          </div>
          <span className={`hsr-set-name rarity-${cone.rarity}`}>
            {cone.name}
          </span>
          {superImp && <span className="cone-super">(S{superImp})</span>}
        </Accordion.Header>
        <Accordion.Body>
          <div className="hsr-set-content">
            <div className={`hsr-set-description`}>
              {renderRichText(cone.skillDescription, options)}
            </div>
          </div>
          <div className="hsr-set-source">
            <p>
              Source:{' '}
              {cone.source ? (
                cone.source.sort().map((source, idx, arr) => {
                  return (
                    <span key={idx}>
                      {source}{' '}
                      {arr.length - 1 === idx ? <></> : <>&nbsp;|&nbsp;</>}
                    </span>
                  );
                })
              ) : (
                <span className="no-spec">-</span>
              )}
            </p>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
