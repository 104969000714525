import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IHSRCone } from '../../../common/model/graphql-types';

interface IProps {
  employee: IHSRCone;
  enablePopover?: boolean;
  showLabel?: boolean;
}

export const HSRLightConeIcon: React.FC<IProps> = ({ employee }) => {
  const image = employee.smallImage
    ? getImage(employee.smallImage.localFile.childImageSharp)
    : null;
  return (
    <div className={`hsr-cone-icon rarity-${employee.rarity}`}>
      {image && (
        <GatsbyImage
          className="disable-transition"
          image={image}
          alt={`${employee.name}`}
        />
      )}
    </div>
  );
};
