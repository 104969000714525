import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IHSRCone } from '../../../common/model/graphql-types';

import './hsr-light-cone-card.scss';

interface IProps {
  cone: IHSRCone;
  superImp?: string;
  hidename?: boolean;
}

export const HSRLightConeMin: React.FC<IProps> = ({
  cone,
  superImp,
  hidename
}) => {
  const image = cone.smallImage
    ? getImage(cone.smallImage.localFile.childImageSharp)
    : null;
  return (
    <>
      <div className={`hsr-set-image rarity-${cone.rarity}`}>
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${cone.name}`}
          />
        )}
      </div>
      {!hidename && cone.name && <span className="cone-name">{cone.name}</span>}
      {superImp && <span className="cone-super">(S{superImp})</span>}
    </>
  );
};
