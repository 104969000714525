import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { IHSRCone } from '../../../common/model/graphql-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './hsr-light-cone-card.scss';
import { HSRStat } from './hsr-stat';

interface IProps {
  cone: IHSRCone;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const HSRLightConeCard: React.FC<IProps> = ({ cone }) => {
  const image = cone.smallImage
    ? getImage(cone.smallImage.localFile.childImageSharp)
    : null;
  return (
    <>
      <div className="hsr-cone-box box">
        <div className="hsr-cone-header">
          <div className={`hsr-cone-image rarity-${cone.rarity}`}>
            {image && (
              <GatsbyImage
                className="disable-transition"
                image={image}
                alt={`${cone.name}`}
              />
            )}
          </div>
          <div className="hsr-cone-data">
            <h4>{cone.name}</h4>
            <div className="hsr-cone-info">
              <p>
                Rarity:{' '}
                <strong className={`rarity-hsr rarity-${cone.rarity}`}>
                  {cone.rarity}★
                </strong>
              </p>
              <p>
                Path: <strong>{cone.path}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="hsr-cone-content">
          <div className={`skill-with-coloring`}>
            {renderRichText(cone.skillDescription, options)}
          </div>
        </div>
        <div className="hsr-cone-stats">
          <div className="hsr-cone-stat-single">
            <HSRStat stat="hp" />
            <strong>
              +
              {cone.stats.hp.value_level_max
                ? cone.stats.hp.value_level_max
                : '?'}
            </strong>
          </div>
          <div className="hsr-cone-stat-single">
            <HSRStat stat="atk" />
            <strong>
              +
              {cone.stats.atk.value_level_max
                ? cone.stats.atk.value_level_max
                : '?'}
            </strong>
          </div>
          <div className="hsr-cone-stat-single">
            <HSRStat stat="def" />
            <strong>
              +
              {cone.stats.def.value_level_max
                ? cone.stats.def.value_level_max
                : '?'}
            </strong>
          </div>
        </div>
      </div>
    </>
  );
};
